"use client";

import { useEffect, useState } from "react";

export const useViewportDimensions = () => {
  const [dimensions, setDimensions] = useState<{
    viewportWidth: number;
    viewportHeight: number;
  }>({ viewportWidth: 0, viewportHeight: 0 });

  useEffect(() => {
    const onResize = () => {
      setDimensions({
        viewportWidth: window.innerWidth,
        viewportHeight: window.innerHeight,
      });
    };
    onResize();

    window.addEventListener("resize", onResize, { passive: true });

    return () => window.removeEventListener("resize", onResize);
  }, []);

  return dimensions;
};
